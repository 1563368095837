
import { defineComponent } from "vue";
import {
  Form,
  Button,
  Input,
  message,
  Switch,
  Select,
  Upload,
  Image,
} from "ant-design-vue";
import {
  PlusCircleOutlined,
  CloseOutlined,
  PictureOutlined,
  EyeFilled,
  EditFilled,
  SearchOutlined,
} from "@ant-design/icons-vue";
import CategorySelect from "@/components/singleProduct/CategorySelect.vue";
import BrandSelect from "@/components/singleProduct/BrandSelect.vue";
import BrandModal from "@/components/brand/Modal.vue";
import CategroyModal from "@/components/singleProduct/CategroyModal.vue";
import ItemList from "@/components/singleProduct/ItemList.vue";
import {
  getSingleProductById,
  imageUpload,
  saveSingleProduct,
  updateSingleProductById,
  customParameters,
  listBaseValueByCode,
} from "@/api/terminalList.js";
import { SingleProductRules } from "@/rules/rules.js";
import Custom from "@/components/singleProduct/Custom.vue";
const { Item } = Form;
const { Option } = Select;
export default defineComponent({
  components: {
    Form,
    Button,
    FormItem: Item,
    Input,
    Switch,
    PlusCircleOutlined,
    Select,
    Option,
    CategorySelect,
    BrandSelect,
    Upload,
    CloseOutlined,
    Image,
    BrandModal,
    CategroyModal,
    ItemList,
    PictureOutlined,
    EyeFilled,
    EditFilled,
    Custom,
    SearchOutlined,
  },
  data() {
    return {
      formState: {
        isPackaging: 0,
        // stockUnitCodeKeep:'',//选中项的单位
        // brandName: "",
        // brandNameList: [],
        // categoryName: "",
        // categoryNameList: [],
        // packSpCount: '',
        // packSpId: '',
        // packSpName: '',
        // salesPrice: '',
        // shelfLife: '',
        // size: '',
        // sizeHigh: '',
        // sizeLong: '',
        // sizeWide: '',
        // spNickName: '',
        // stockUnitCode: "",
        extraList: [1, 1],
      },
      rules: SingleProductRules,
      //是否关联包装单位
      relation: false,
      showUnclassified: true,
      brandUnclassified: true,
      imgFlag: true,
      notUploaded: true,
      nowDate: "",
      fileUrl: null,
      filelist: [],
      firstImg: "",
      showCustom: false, //自定义字段未加载结束之前不显示
      listBaseValue: [], //单位的数组
      todoEdit: false, //点击编辑按钮之后才可以编辑
      subLoading: false, //提交按钮的加载状态
    };
  },
  methods: {
    //操作关联按钮
    handleRelation() {
      if (this.relation) {
        (this as any).formState.isPackaging = 1;
      } else {
        (this as any).formState.isPackaging = 0;
        (this as any).formState.packSpCount = "";
        (this as any).formState.packSpId = "";
      }
    },
    //控制显示
    changeUnclassified() {
      (this as any).formState.categoryNameList = [];
      this.showUnclassified = false;
      setTimeout(() => {
        let input: any = document.querySelector(".cascader");
        if (input) {
          input.click();
        }
      }, 0);
    },
    changeBrandUnclassified() {
      (this as any).formState.brandNameList = [];
      this.brandUnclassified = false;
      setTimeout(() => {
        let input: any = document.querySelector(".cascader2");
        if (input) {
          input.click();
        }
      }, 0);
    },
    //上传图片
    handleChange(info) {
      // this.notUploaded = false;
      if (info.fileList.length < 1) {
        this.notUploaded = true;
      }
    },
    //检验图片格式
    beforeUpload(file, fileList) {
      const isJpgOrPng =
        file.type === "image/jpeg" || file.type === "image/png";
      if (!isJpgOrPng) {
        message.error("上传图片应为PNG或JPG格式,请重新上传", 4);
        fileList.length = 0;
      }
      const isLt5M = file.size / 1024 / 1024 < 2;
      if (!isLt5M) {
        message.error("图片大小应小于2M,请重新上传", 4);
        fileList.length = 0;
      }
      return isJpgOrPng && isLt5M;
    },
    //验证图片是否有效
    checkImg(src) {
      if (src) {
        if (src.includes(".jp") || src.includes(".png")) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    //替换默认上传行为拦截文件
    customRequest(data) {
      const formData = new FormData();
      let name = data.file.name;
      name = name.substring(name.lastIndexOf(".") + 1);
      formData.append("file", data.file);
      formData.append("module", `singleproduct`);
      formData.append("scene", `image`);
      formData.append("format", name);
      imageUpload(formData).then((res) => {
        //
        if (res.data.success) {
          (this as any).formState.spImgUrl = res.data.data.fileUrl;
          (this as any).firstImg = res.data.data.absoluteFileUrl;
          this.notUploaded = false;
          data.onSuccess();
        }
        // else {
        //   message.error(res.data.errormessage);
        // }
      });
    },
    getDate() {
      var date = new Date();
      var seperator1 = "";
      var year = date.getFullYear();
      var month: any = date.getMonth() + 1;
      var strDate: any = date.getDate();
      if (month >= 1 && month <= 9) {
        month = "0" + month;
      }
      if (strDate >= 0 && strDate <= 9) {
        strDate = "0" + strDate;
      }
      var currentdate = year + seperator1 + month + seperator1 + strDate;
      return currentdate;
    },
    changeImg() {
      this.imgFlag = true;
      (this as any).fileUrl = "";
      this.notUploaded = true;
    },
    onSubmit() {
      (this as any).$refs.formRef
        .validate()
        .then(() => {
          let parameters: any = JSON.parse(JSON.stringify(this.formState));
          Object.keys(parameters).forEach((key) => {
            if (typeof parameters[key] == "string") {
              parameters[key] = parameters[key].trim();
            }
          });
          parameters.brandName = parameters.brandNameList.join("/");
          parameters.categoryName = parameters.categoryNameList.join("/");

          let Extra = {};
          for (let i = 0; i < parameters.extraList.length; i++) {
            let key = parameters.extraList[i].extraCode;
            Extra[key] = parameters[key];
          }

          //
          if (this.$route.params.id != "new") {
            this.subLoading = true;
            console.log(parameters);

            updateSingleProductById({
              id: this.$route.params.id,
              spName: parameters.spName.trim(),
              spCode: parameters.spCode.trim(),
              spBarCode: parameters.spBarCode.trim(),
              spNickName: parameters.spNickName,
              stockUnitCode: parameters.stockUnitCode,
              isPackaging: parameters.isPackaging,
              categoryCode: parameters.categoryCode,
              categoryName: parameters.categoryName,
              brandCode: parameters.brandCode,
              brandName: parameters.brandName,
              salesPrice: parameters.salesPrice,
              shelfLife: parameters.shelfLife,
              sizeLong: parameters.sizeLong,
              sizeWide: parameters.sizeWide,
              sizeHigh: parameters.sizeHigh,
              spImgUrl: parameters.spImgUrl,
              status: parameters.status,
              packSpId: parameters.packSpId,
              packSpCount: parameters.packSpCount,
              ...Extra,
            })
              .then((res) => {
                this.subLoading = false;
                if (res.data.success) {
                  message.success("编辑单品成功");
                  this.$router.push("/masterData/singleProduct/list");
                }
                // else {
                //   message.error(res.data.errorMessage);
                // }
              })
              .catch(() => {
                this.subLoading = false;
              });
          } else {
            this.subLoading = true;
            saveSingleProduct({
              spName: parameters.spName.trim(),
              spCode: parameters.spCode,
              spBarCode: parameters.spBarCode,
              spNickName: parameters.spNickName,
              stockUnitCode: parameters.stockUnitCode,
              isPackaging: parameters.isPackaging,
              categoryCode: parameters.categoryCode,
              categoryName: parameters.categoryName,
              brandCode: parameters.brandCode,
              brandName: parameters.brandName,
              salesPrice: parameters.salesPrice,
              shelfLife: parameters.shelfLife,
              sizeLong: parameters.sizeLong,
              sizeWide: parameters.sizeWide,
              sizeHigh: parameters.sizeHigh,
              spImgUrl: parameters.spImgUrl,
              packSpId: parameters.packSpId,
              packSpCount: parameters.packSpCount,
              status: 1,
              ...Extra,
            })
              .then((res) => {
                if (res.data.success) {
                  this.subLoading = false;
                  message.success("新增单品成功");
                  this.$router.push("/masterData/singleProduct/list");
                }
                // else {
                //   message.error(res.data.errorMessage);
                // }
              })
              .catch(() => {
                this.subLoading = false;
              });
          }
        })
        .catch(() => {
          message.warning("录入信息不规范请修改");
        });
    },
    cancel() {
      history.go(-1);
    },
    //ml g 不能关联单位
    // handleUnit() {
    //   if (
    //     (this as any).formState.stockUnitCode === "ml" ||
    //     (this as any).formState.stockUnitCode === "g"
    //   ) {
    //     this.relation = false;
    //     (this as any).formState.isPackaging = 0;
    //     (this as any).formState.packSpCount = "";
    //     (this as any).formState.packSpId = "";
    //   }
    // },
    //新增品牌品类
    addCategroy() {
      (this as any).$refs.CategroyModal.showModal();
    },
    addBrand() {
      (this as any).$refs.brandModal.showModal();
    },
    //子传父重新获取一级品类品牌数据
    reloadCategroyBrand(type) {
      if (type == "brand") {
        if ((this as any).$refs.brandSelect) {
          (this as any).$refs.brandSelect.getNewOptions();
        }
      } else {
        if ((this as any).$refs.CategorySelect) {
          (this as any).$refs.CategorySelect.getNewOptions();
        }
      }
    },
    //预览图片
    previewImg() {
      let photo: any = document.querySelector(".previewPhoto");
      setTimeout(() => {
        if (photo) {
          photo.click();
        }
      }, 0);
    },
    checklabel() {
      if ((this as any).formState.stockUnitCode) {
        return "1" + (this as any).formState.stockUnitCode;
      } else {
        return "1单位";
      }
    },
  },
  watch: {},
  mounted() {
    //获取单位列表
    listBaseValueByCode().then((res: any) => {
      if (res.data.success) {
        this.listBaseValue = res.data.data;
      }
    });

    (this as any).rules = {
      ...SingleProductRules,
      packSpId: [
        {
          trigger: "blur",
          validator: async (rule, value) => {
            if (this.formState.isPackaging) {
              if (value === undefined) {
                value = "";
              }
              var value: any = String(value);
              if (value.length < 1) {
                return Promise.reject("请选择关联的包装");
              }
            }
          },
        },
      ],
      packSpCount: [
        {
          trigger: "blur",
          validator: async (rule, value) => {
            if (this.formState.isPackaging) {
              if (value === undefined) {
                value = "";
              }
              var reg = new RegExp("^[0-9]+$");
              var value = value.trim();
              if (value.length < 1) {
                return Promise.reject("请输入关联包装的数量");
              }
              if (!reg.test(value) && value !== "") {
                return Promise.reject("仅可输入数字");
              }
            }
          },
        },
        { min: 0, max: 5, message: "请输入0-99999之间的数字", trigger: "blur" },
      ],
      categoryName: [
        {
          required: true,
          message: "请选择品类",
          validator: async (rule, value) => {
            if ((this as any).formState.categoryNameList.length == 0) {
              return Promise.reject("请选择品类");
            }
          },
        },
      ],
      brandName: [
        {
          required: true,
          message: "请选择品牌",
          validator: async (rule, value) => {
            if ((this as any).formState.brandNameList.length == 0) {
              return Promise.reject("请选择品牌");
            }
          },
        },
      ],
    };
    if (this.$route.params.id !== "new") {
      customParameters("singleProduct").then((res1: any) => {
        //获取数据
        getSingleProductById(this.$route.params.id).then((res: any) => {
          let result: any = res.data.data;
          for (var attr in result.extraField) {
            result[attr] = result.extraField[attr];
          }
          for (var attr in result) {
            if (typeof result[attr] === "number") {
              result[attr] = String(result[attr]);
            }
            if (result[attr] === null) {
              result[attr] = "";
            }
          }
          result.categoryNameList = result.categoryName.split("/");
          result.brandNameList = result.brandName.split("/");
          this.formState = result;
          this.imgFlag = !this.checkImg((this as any).formState.imgUrl);
          if ((this as any).formState.isPackaging == 0) {
            this.relation = false;
          } else {
            this.relation = true;
          }
          (this as any).showCustom = true;
          (this as any).formState.extraList = res1.data.data.filter((item) => {
            return item.status;
          });
        });
      });
    } else {
      //获取扩展字段
      this.todoEdit = true;
      customParameters("singleProduct").then((res: any) => {
        //
        (this as any).formState.extraList = res.data.data.filter((item) => {
          return item.status;
        });
        (this as any).showCustom = true;
        //
      });
    }
  },
});
