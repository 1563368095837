
import { defineComponent } from "vue";
import { allBrandList, brandDownList } from "@/api/terminalList.js";
import { Cascader } from "ant-design-vue";
export default defineComponent({
  components: {
    Cascader,
  },
  data() {
    return {
      options: [],
    };
  },
  props: {
    formState: {
      type: Object,
      defaul: {},
    },
  },
  methods: {
    convertKey(arr, level) {
      level++;
      arr.forEach((item) => {
        item.level = level;
        if (item["childBrandList"].length != 0) {
          item.level = level;
          this.convertKey(item.childBrandList, level);
        }
      });
      return arr;
    },
    loadData(selectedOptions) {
      //  
      let targetOption = selectedOptions[selectedOptions.length - 1];
      targetOption.loading = true; // load options lazily
      brandDownList(targetOption.brandCode).then(() => {
        targetOption.loading = false;
        //  
        let newOptions = targetOption.childBrandList.map((item) => {
          let checkIsLeaf;
          if (item.childBrandList.length) {
            checkIsLeaf = false;
          } else {
            checkIsLeaf = true;
          }
          //   if (item.level === 3&&this.$route.params.id==='new') {
          //     checkIsLeaf = true;
          //   }
          return {
            ...item,
            value: item.brandName,
            label: item.brandName,
            isLeaf: checkIsLeaf,
          };
        });
        targetOption.children = newOptions;
        this.options = [...this.options];
      });
    },
    //获取第一列数据
    getNewOptions() {
      allBrandList().then((res: any) => {
        let level = 0;
        this.convertKey(res.data.data, level);
        let newOptions = res.data.data.map((item) => {
          let checkIsLeaf;
          if (item.childBrandList.length) {
            checkIsLeaf = false;
          } else {
            checkIsLeaf = true;
          }
          return {
            ...item,
            value: item.brandName,
            label: item.brandName,
            isLeaf: checkIsLeaf,
          };
        });
        this.options = newOptions;
        //  
      });
    },
    change(value, selectedOptions) {
      //  
      //  
      (this as any).formState.brandCode =
        selectedOptions[selectedOptions.length - 1].brandCode;
    },
    blur() {
      (this as any).formState.brandName = (
        this as any
      ).formState.brandNameList.join("/");
    },
  },
  mounted() {
    this.getNewOptions();
  },
});
