import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Cascader = _resolveComponent("Cascader")!

  return (_openBlock(), _createBlock(_component_Cascader, {
    value: _ctx.formState.brandNameList,
    "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formState.brandNameList) = $event)),
    options: _ctx.options,
    placeholder: "请至少选择一个品牌",
    "load-data": _ctx.loadData,
    "change-on-select": "",
    onChange: _ctx.change,
    onBlur: _ctx.blur
  }, null, 8, ["value", "options", "load-data", "onChange", "onBlur"]))
}